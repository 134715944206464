.hidden {
  display: none !important;
}

.disabled {
opacity: 0.5;
}

.leftBar {
  width: 380px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 1rem;
  z-index: 500;
}

.main {
  background-position: center center;
  background-size: 110% 110%;
  background-color: #000;
}

.classic-container {
  bottom: 10px;
  right: 10px;
  z-index: 500;
}

.logo-container {
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 5000;
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 1rem;
}

.logo {
  max-width: 150px;
  min-width: 100px;
}

.intro-container {
  position: absolute;
  top: 10px;
  left: 55%;
  transform: translate(-50%);
  z-index: 500;
}

.intro-button {
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  background: linear-gradient(60deg, #16aef3, #00bcd4);
  border: 0;
}

.chat-history-parent {
  display: flex;
  flex-direction: column;
  height: 92%;
  gap: 1rem;
}

.chat-history {
  width: 100%;
  height: 100%;
  padding: 10px;
  border-radius: 10px;
  white-space: pre-line;
  overflow: auto;
  color: #fff;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1));
  opacity: 0.5;
  transition: all 0.3s;
}

.chat-history:hover {
  opacity: 1;
}

.chatbox {
  width: 100%;
  border-radius: 20px;
  padding: 4px 15px;
  background: rgba(0, 0, 0, .5) !important;
  color: #fff !important;
  font-size: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.5);
  transition: all 0.3s;
  outline: none !important;
}

.chatbox::placeholder {
  color: rgba(255, 255, 255, .5)
}

.chatbox:hover, .chatbox:focus {
  /* background: rgba(255, 255, 255, .8) !important;
  color: #000 !important; */
  box-shadow: 0px 0px 0px #fff;
  border-color: #fff !important;
}

#flag {
  bottom: 0;
  right: 0;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  overflow: hidden;
  pointer-events: none;
}

#flag svg {
  width: 25px;
  display: block;
}

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  color: #fff;
  z-index: 4000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-box {
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading-text {
  margin-top: 0.8em;
  color: rgb(240, 240, 240);
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Inter", "Segoe UI", "Helvetica Neue", Helvetica, Arial, Roboto, Ubuntu, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  text-align: center;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 72px;
  height: 72px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 4px;
  border: 4px solid #5f97ca;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #5f97ca transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.loading-box .loading-bar {
  position: relative;
}

.loading-percentage {
  width: auto;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn--wiggle {
  animation: 1s 3 wiggle ease;
}

@keyframes wiggle {
  0% {
    transform: rotate(-3deg);
    box-shadow: 0 2px 2px rgba(0,0,0,.2);
  }
  20% {
    transform: rotate(20deg);
  }
  40% {
    transform: rotate(-15deg);
  }
  60% {
    transform: rotate(5deg);
  }
  90% {
    transform: rotate(-1deg);
  }
  100% {
    transform: rotate(0);
    box-shadow: 0 2px 2px rgba(0,0,0,.2);
  }
}

.copyright {
  text-shadow: 1px 1px 2px #000;
}

.copyright a {
  color: #fff;
}

.bottom-right-container {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  z-index: 499;
}

.center-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-40%, -50%);
  z-index: 300;
}

.transition-1s {
  transition: all 1s;
}

.display-box {
  width: 480px;
  height: 270px;
  border: 10px solid rgba(40,40,40,1);
  border-radius: 20px;
  z-index: 200;
}

.display-box.top-right-display {
  top: 20%;
  right: 5%;
}

.display-box iframe, .display-box img, .display-box video {
  width: 100%;
  height: 100%;
}

.mobile-only {
  display: none !important;
}

@media only screen and (max-width: 600px) {
  .mobile-only {
    display: block;
  }
  .intro-container {
    left: 50%;
  }
  .logo-container {
    display: none;
  }
  .display-box {
    display: none;
  }
  .chat-history {
    display: none;
  }
  .leftBar {
    display: flex;
    top: auto;
    bottom: 2.5rem;
    left: 50%;
    transform: translateX(-50%);
  }
  .bottom-right-container {
    bottom: 0.5rem;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }
}